<template>
  <CCard class="circles-chart">
    <CCardHeader @click="cardCollapse = !cardCollapse">
      <h5 class="card-title">
        {{ messages.products.volumeBonus.toUpperCase() }}
      </h5>
    </CCardHeader>
    <CCollapse :show="cardCollapse">
      <CCardBody v-show="!showCard">
        <div class="text-center">
          <Loader
            width="100px"
            height="100px"
            fill-color="#c2c2c2"
            cssClass="fadeIn anim-speed--1s"
          />
        </div>
      </CCardBody>
      <CCardBody v-show="showCard">
        <CRow class="fadeIn anim-speed--3s" xl="12">
          <!-- <CCol xl="12"> -->
          <CCol xl="12" class="flex">
            <div class="circles-chart--graphic-wrapper">
              <p class="home--main-amount">{{ valuesText.purchases }} €</p>
              <p>{{ messages.home.purchases }}</p>
              <div class="circles-chart--canvas-container">
                <canvas
                  id="chart"
                  width="700"
                  height="500"
                  class="circles-chart--canvas"
                ></canvas>
              </div>
            </div>
            <!-- </CCol> -->
            <div class="circles-chart--data-wrapper">
              <div class="circles-chart--legend-blocks">
                <div class="circles-chart--legend-container">
                  <div
                    class="
                      circles-chart--legend circles-chart--legend--estimated
                    "
                  ></div>
                  <div class="value">
                    <p class="home--statement">
                      {{ messages.home.estimatedPurchases }}
                    </p>
                    <p class="home--normal-amount">
                      {{ valuesText.estimated }} €
                    </p>
                  </div>
                </div>
                <div class="circles-chart--legend-container">
                  <div
                    class="circles-chart--legend circles-chart--legend--target"
                  ></div>
                  <div class="value">
                    <p class="home--statement">{{ messages.home.target }}</p>
                    <p class="home--normal-amount">{{ valuesText.target }} €</p>
                  </div>
                </div>
              </div>
              <CRow>
                <CCol class="circles-chart--margin-left">
                  <p class="home--statement">{{ messages.home.completion }}</p>
                  <p class="home--main-amount">{{ valuesText.completion }}%</p>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="circles-chart--margin-left">
                  <CButton
                    color="primary"
                    :to="{ name: 'Estimación incentivos concesión' }"
                    >{{ messages.home.details.toUpperCase() }}</CButton
                  >
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCollapse>
  </CCard>
</template>

<script>
import Api from "@/services/Api";
import formatNum from "@/utils/formatNum";
import langStr from "@/utils/langStr";
import currentFY from "@/services/CurrentFY";
import defaultAreaParam from "@/helpers/defaultAreaParam";

const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");
export default {
  name: "VolumeBonusCard",
  components: {
    Loader,
  },
  data() {
    return {
      user: null,
      lang: null,
      messages: null,
      chart: null,
      rad: 5,
      values: {
        purchases: 0,
        target: 0,
        estimated: 0,
        completion: 0,
      },
      cardCollapse: true,
      showCard: false,
    };
  },
  computed: {
    valuesText() {
      return {
        purchases: formatNum.get(this.values.purchases, 0),
        estimated: formatNum.get(this.values.estimated, 0),
        target: formatNum.get(this.values.target, 0),
        completion: formatNum.get(this.values.completion, 0),
      };
    },
    normalized() {
      return {
        first:
          this.values.target < this.values.estimated
            ? (this.values.target / this.values.estimated) * 40 * this.rad
            : 40 * this.rad,
        second:
          this.values.target < this.values.estimated
            ? 40 * this.rad
            : (this.values.estimated / this.values.target) * 40 * this.rad,
      };
    },
    coords() {
      return {
        first:
          this.values.target < this.values.estimated
            ? [50 * this.rad, 90 * this.rad - this.normalized.first]
            : [50 * this.rad, 50 * this.rad],
        second:
          this.values.target < this.values.estimated
            ? [90 * this.rad, 50 * this.rad]
            : [90 * this.rad, 90 * this.rad - this.normalized.second],
      };
    },
  },
  methods: {
    getVolumeBonusData() {
      currentFY().then((fy) => {
        const defaultArea = defaultAreaParam(this.user);
        const paramArea = {};
        paramArea[defaultArea.key] = defaultArea.value;
        const currentMonth = new Date().getMonth() + 1;
        const params = {
          fiscalYear: fy,
          sinceMonth: currentMonth,
          upToMonth: currentMonth,
          productCodes: ["BV"],
          languageId: langStr.getId(this.lang),
          ...paramArea,
        };
        this.makeQuery(params)
          .then((results) => {
            this.setVariables(results).then(() => {
              this.showCard = true;
              this.drawLines();
              this.drawTargetCircle();
              this.drawEstimatedCircle();
            });
          })
          .catch((e) => console.log(e));
      });
    },
    async makeQuery(params) {
      const results = await Api.sendPost("dashboard/productEstimation", params);
      return results.data.data;
    },
    setVariables(array) {
      return new Promise((resolve, reject) => {
        const volumeBonusRow = array.filter(
          (elem) => elem.productCode === "BV"
        )[0];
        console.log(volumeBonusRow);

        if (array !== undefined) {
          this.values.purchases = volumeBonusRow.purchasesCount;
          this.values.estimated = volumeBonusRow.purchasesCountEstimated;
          this.values.target = volumeBonusRow.targetPurchases;
          this.values.completion = volumeBonusRow.completion;
          resolve(this.values);
        } else {
          reject(new Error("VOLUME BONUS DATA FORMAT ERROR"));
        }
      });
    },
    drawLines() {
      const chart = this.chart;
      const rad = this.rad;
      // Bottom line
      chart.lineWidth = rad / 2;
      chart.strokeStyle = "#33333399";
      chart.beginPath();
      chart.moveTo(0, 90 * rad);
      chart.lineTo(140 * rad, 90 * rad);
      chart.stroke();

      // Top line
      chart.setLineDash([0, 2 * rad]);
      chart.lineWidth = rad;
      chart.lineCap = "round";
      chart.strokeStyle = "#33333380";
      chart.beginPath();
      chart.moveTo(0, this.coords.first[1] - this.normalized.first);
      chart.lineTo(140 * rad, this.coords.first[1] - this.normalized.first);
      chart.stroke();
    },
    drawTargetCircle() {
      // Target value circle
      const circle = new Path2D();
      circle.arc(
        this.coords.first[0],
        this.coords.first[1],
        this.normalized.first,
        0,
        2 * Math.PI
      );
      this.chart.strokeStyle = "#c3002f";
      this.chart.stroke(circle);
    },
    drawEstimatedCircle() {
      // Estimated value circle
      const circle = new Path2D();
      circle.arc(
        this.coords.second[0],
        this.coords.second[1],
        this.normalized.second,
        0,
        2 * Math.PI
      );
      this.chart.fillStyle = "#c3002faa";
      this.chart.fill(circle);
    },
  },
  created() {
    this.user = this.$store.getters.getCurrentUser;
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
  },
  mounted() {
    const canvas = document.getElementById("chart");
    const ctx = canvas.getContext("2d");
    this.chart = ctx;

    this.getVolumeBonusData();
  },
};
</script>

<style lang="scss" scoped>
$primary: #c3002f;

$colPadding: 15px;
$circleLegendDiameter: 1.5em;
$circleLegendRightMargin: 0.8em;

.circles-chart {
  &--canvas-container {
    width: 100%;
    max-width: 550px;
  }

  &--canvas {
    max-width: 100%;
  }

  &--data-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  &--margin-left {
    margin-left: calc(
      #{$colPadding} + #{$circleLegendDiameter/2} + #{$circleLegendRightMargin}
    );
  }

  &--legend-blocks {
    display: block;
  }

  &--legend-container {
    display: flex;

    &:first-child {
      margin-right: 2rem;
    }
  }

  &--legend {
    // padding-left: calc(5px + 1.8em);
    &::before {
      display: block;
      content: "";
      width: $circleLegendDiameter;
      height: $circleLegendDiameter;
      opacity: 0.7;
      border-radius: 50%;
      transform: translate(0px, 5px);
      margin-right: $circleLegendRightMargin;
    }

    &--estimated {
      &::before {
        background: $primary;
      }
    }

    &--target {
      &::before {
        border: 2px dotted $primary;
      }
    }
  }
}
@media screen and (min-width: 1560px) {
  .circles-chart {
    &--legend-blocks {
      display: flex;
    }
  }
}

@media screen and (min-width: 1730px) {
  .circles-chart {
    &--graphic-wrapper {
      width: 60%;
      padding-right: 1rem;
    }

    &--data-wrapper {
      width: 40%;
    }

    &--legend-blocks {
      display: block;
    }
  }
}
</style>
